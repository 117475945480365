.operation {
    margin-top: -112px;
    width: 100vw;
    height: 500px;
    background-color: #13131a;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding-top: 40px;
    clip-path: polygon(100% 1%, 100% 100%, 0% 100%, 0% 22.3%);
    .areaTitle {
        h2 {
            color: white;
            font-size: 30px;
            font-weight: 600;
            text-transform: capitalize;
            line-height: 30px;
        }
    }
    .areaText {
        color: white;
        p {
            font-size: 14px;
            font-weight: 500;
            line-height: 25px;
        }
    }
}
