.teamContainer {
    .teamTitle {
        h1 {
            font-size: 32px;
            font-weight: 600;
            color: #333;
            text-transform: capitalize;
            margin-bottom: 10px;
            line-height: 25px;
        }
    }
    .teamMember {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        column-gap: 20px;
        row-gap: 20px;
        .memberItem {
            background: rgba(245, 245, 250, 0.5);
            -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(10px);
            border: 1px solid rgba(245, 245, 250, 0.25);
            min-height: 250px;
            img {
                width: 100%;
                height: 200px;
            }
            .memberText {
                margin: 5px 10px 5px 10px;
                h4 {
                    font-size: 15px;
                    font-weight: 500;
                    line-height: 25px;
                    color: #333;
                }
                .duties {
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 25px;
                    color: #555;
                }
            }
        }
    }
}
