.topNavBar {
  height: 100px;
  display: flex;
  flex-direction: row;
  padding: 0 20px;
  align-items: center;
  background-color: #f5f5fa;
  justify-content: space-between;
  .logoBox {
    height: 50px;
    width: 50px;
    a {
      text-decoration: none;
      img {
        height: 100%;
        width: 100%;
        border-radius: 50%;
        object-fit: cover;
      }
    }
  }
  .navItems {
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 25px;
    align-self: center;
    .listItem {
      list-style: none;
      a {
        text-decoration: none;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        gap: 7px;
        span {
          color: #333;
          font-size: 15px;
          font-weight: 500;
          text-transform: uppercase;
        }
        .icons {
          font-size: 26px;
          font-weight: bold;
          color: steelblue;
        }
      }
    }
  }
  // .rightBox {
  //   display: flex;
  //   align-items: center;
  //   gap: 10px;
  //   .mode{
  //      a{
  //       display: flex;
  //       align-items: center;
  //       .icons{
  //           font-size: 22px;
  //           font-weight: bold;
  //           color: steelblue;
  //       }
  //      }
  //   }
  //   .languageBox {
  //     a {
  //       text-decoration: none;
  //       display: flex;
  //       align-items: center;
  //       gap: 7px;
  //       span{
  //           color: #333;
  //           font-size: 15px;
  //           font-weight: 500;
  //           text-transform: uppercase;
  //       }
  //       .icons{
  //           font-size: 22px;
  //           font-weight: bold;
  //           color: blue;
  //       }
  //     }
  //   }
  // }
}
