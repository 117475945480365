.banner{
    height: 100vh;
    width: 100vw;
    background-image: linear-gradient(rgba(0, 128, 0, 0.349), rgba(0, 128, 0, 0.349)),url("../../../public/Banner-1.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    .titleBox{
        position: absolute;
        left: 35px;
        top: 30px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        h1{
            color: white;
            font-size: 35px;
            font-weight: bold;
        }
        h5{
            color: white;
            text-transform: uppercase;
            font-size: 16px;
            font-weight: 600;
        }
    }
}