.contactContainer {
    padding: 20px 200px;
    h4 {
        text-align: center;
        font-size: 25px;
        font-weight: bold;
        color: steelblue;
        line-height: 30px;
        text-transform: uppercase;
    }
    form {
        .formInput {
            margin-bottom: 12px;
        }
        button {
            padding: 12px;
            background-color: steelblue;
            border: none;
            outline: none;
            color: white;
            font-size: 14px;
            font-weight: 500;
            border-radius: 15px;
            cursor: pointer;
        }
    }
}
