.general{
    display: flex;
    flex-direction: row;
    gap: 5px;
    margin-top: 10px;
    .mainArea{
        flex: 10;
        background-color: #FFF;
        padding: 15px;
    }
}