.aboutContainer {
  width: 100%;
  height: 100%;
  margin-bottom: 15px;
  h1 {
    color: #333;
    font-size: 32px;
    font-weight: 600;
    text-transform: capitalize;
    margin-bottom: 10px;
    line-height: 25px;
  }
  p {
    font-size: 16px;
    font-weight: 500;
    line-height: 25px;
    text-align: justify;
    color: #555;
  }
  .values {
    margin-top: 16px;
    .value_header {
      margin-bottom: 10px;
      font-size: 20px;
      color: #333;
      font-weight: 600;
      text-transform: capitalize;
    }
    .value_description {
      display: flex;
      flex-direction: column;
      gap: 5px;
      p {
        font-size: 16px;
        font-weight: 500;
        line-height: 25px;
        text-align: justify;
        color: #555;
      }
      .value_list {
        text-decoration: none;
        list-style: decimal;
        font-size: 16px;
        font-weight: 500;
        line-height: 25px;
        text-align: justify;
        color: #555;
        margin-left: 15px;
      }
    }
  }
  .vision {
    margin-top: 16px;
    .vision_header {
      margin-bottom: 10px;
      font-size: 20px;
      color: #333;
      font-weight: 600;
      text-transform: capitalize;
    }
    .vision_description {
      p {
        font-size: 16px;
        font-weight: 500;
        line-height: 25px;
        text-align: justify;
        color: #555;
      }
    }
  }
  .mission {
    margin-top: 10px;
    .mission_header {
      margin-bottom: 10px;
      font-size: 20px;
      color: #333;
      font-weight: 600;
      text-transform: capitalize;
    }
    .mission_description {
      display: flex;
      flex-direction: column;
      gap: 5px;
      p {
        font-size: 16px;
        font-weight: 500;
        line-height: 25px;
        text-align: justify;
        color: #555;
      }
      .mission_list {
        text-decoration: none;
        list-style: decimal;
        font-size: 16px;
        font-weight: 500;
        line-height: 25px;
        text-align: justify;
        color: #555;
        margin-left: 15px;
      }
    }
  }
  .programmes {
    margin-top: 10px;
    .programmes_header {
      margin-bottom: 10px;
      font-size: 20px;
      color: #333;
      font-weight: 600;
      text-transform: capitalize;
    }
    .programmes_description {
      display: flex;
      flex-direction: column;
      gap: 5px;
      p {
        font-size: 16px;
        font-weight: 500;
        line-height: 25px;
        text-align: justify;
        color: #555;
      }
      .programmes_list {
        text-decoration: none;
        list-style: decimal;
        font-size: 16px;
        font-weight: 500;
        line-height: 25px;
        text-align: justify;
        color: #555;
        margin-left: 15px;
      }
    }
  }
  .our_management {
    margin-top: 10px;
    .management_header {
      margin-bottom: 10px;
      font-size: 20px;
      color: #333;
      font-weight: 600;
      text-transform: capitalize;
    }
    .management_description {
      p {
        font-size: 16px;
        font-weight: 500;
        line-height: 25px;
        text-align: justify;
        color: #555;
      }
    }
    .organization_structure{
        margin-top: 10px;
        .organization_structure_header {
            margin-bottom: 10px;
            font-size: 20px;
            color: #333;
            font-weight: 600;
            text-transform: capitalize;
          }
          .structure_list{
            background-color: whitesmoke;
            text-decoration: none;
            list-style: none;
            color: #555;
            line-height: 25px;
            padding: 20px 16px;
            width: 400px;
            font-size: 16px;
            li{
                border-bottom: 0.75px solid #888;
            }
          }
    }
  }
}
