.details{
    display: flex;
    justify-content: center;
    align-items: center;
    .itemBox{
        width: 600px;
        img{
            width: 100%;
        }
        .itemText{
            margin-top: 10px;
            h4{
                color: #555;
                text-transform: uppercase;
                font-size: 22px;
                font-weight: 700;
                line-height: 28px;
                margin-bottom: 5px;
            }
            p{
                color: #555;
                font-size: 16px;
                font-weight: 400;
                line-height: 25px;
            }
            .category,.year{
                font-size: 16px;
                font-weight: 700;
                font-style: italic;
                color: #666;
            }
        }
    }
}