.latestEvent{
    background-color: #F5F5FA;
    padding: 20px 10px;
    .eventHeader{
        h1{
            font-size: 30px;
            font-weight: 600;
            color: #333;
            text-transform: capitalize;
            line-height: 25px;
        }
        padding-left: 15px;
        margin-bottom: 15px;
    }
    .eventContainer{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 15px;
        .eventItem{
            height: 250px;
            background-color: white;
            border-top-right-radius: 20px;
            img{
                width: 60%;
                height: 80%;
                border-radius:8px ;
                transform: scaleX(1);
                &:hover{
                    border-radius: 0;
                    transform: scale(2.0);
                    transition: all 0.5s ease-in;
                }
            }
            display: flex;
            justify-content: center;
            padding-right: 5px;
            .activitiesText{
                display: flex;
                flex-direction: column;
                gap: 10px;
                margin-left: 5px;
                padding-top: 5px;
                padding-bottom: 5px;
                h4{
                    font-size: 13px;
                    font-weight: 500;
                    text-transform: uppercase;
                    color: steelblue;
                }
                p{
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 15px;
                    color: #555;
                }
                .activitiesButton{
                   a{
                    display: flex;
                    align-items: center;
                    width: fit-content;
                    text-decoration: none;
                    font-size: 10px;
                    font-weight: 300;
                    background-color: green;
                    padding: 10px 5px;
                    color: #fff;
                    opacity: 0.8;
                    &:hover{
                        opacity: 1;
                        transition: all 0.5s ease-in;
                    }
                   }
                }
            }
        }
    }
}