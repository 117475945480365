.inputContainer{
    display: flex;
    flex-direction: column;
    gap: 5px;
    label{
        font-size: 14px;
        font-weight: 500;
        color: #222;
    }
    input{
        height: 40px;
        padding: 5px 6px;
        color: #333;
        font-size: 15px;
        font-weight: 500;
        border: 1px solid #555;
        width: 100%;
        outline: none;
        &::placeholder{
        color: #444;
        font-size: 15px;
        font-weight: 500;
        }
    }

}