.activitiesContainer{
    .activitiesHeading{
        h1 {
            font-size: 32px;
            font-weight: 600;
            color: #333;
            text-transform: capitalize;
            margin-bottom: 10px;
            line-height: 25px;
        }
    }
    .activities{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 30px;
        .activitiesItem{
            padding: 10px 5px;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            background: rgba(245, 245, 250, 0.5);
            img{
                width: 100%;
                height: 250px;
                opacity: 0.8;
                transform: scaleX(1);
                &:hover{
                    opacity: 1;
                    transform: scale(2.0);
                    transition: all 0.5s ease-in;
                }
            }
            .activitiesText{
                margin-bottom: 10px;
                h4{
                    font-size: 15px;
                    font-weight: 500;
                    line-height: 25px;
                    text-transform: uppercase;
                    color: steelblue;
                    margin-bottom: 5px;
                }
                p{
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 22px;
                    color: #555;
                }
            }
            .activitiesButton{
                a{
                    display: flex;
                    align-items: center;
                    width: fit-content;
                    text-decoration: none;
                    font-size: 10px;
                    font-weight: 300;
                    background-color: green;
                    padding: 10px 5px;
                    color: #fff;
                    opacity: 0.8;
                    &:hover{
                        opacity: 1;
                        transition: all 0.5s ease-in;
                    }
                }
            }
        }
    }
}