.textAreaContainer{
    display: flex;
    flex-direction: column;
    gap: 5px;
    label{
        font-size: 14px;
        font-weight: 500;
        color: #222;
    }
    textarea{
        height: 300px;
        padding: 5px 6px;
        color: #333;
        font-size: 15px;
        font-weight: 500;
        border: 1px solid #555;
        width: 100%;
        resize: none;
        outline: none;
        border-radius: 10px;
    }
}