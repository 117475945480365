.footer {
    margin-top: 20px;
    background-color: steelblue;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 30px;
    .whatwedo {
        h6 {
            font-size: 14px;
            font-weight: 500;
            color: white;
            text-transform: uppercase;
            margin-bottom: 5px;
        }
        ul {
            margin: 0;
            padding: 0;
            list-style: none;
            display: flex;
            flex-direction: column;
            gap: 5px;
            li {
                padding: 5px;
                font-size: 13px;
                font-weight: 400;
                color: #fff;
            }
        }
    }
    .whoweare {
        h6 {
            font-size: 14px;
            font-weight: 500;
            color: white;
            text-transform: uppercase;
            margin-bottom: 5px;
        }
        ul {
            margin: 0;
            padding: 0;
            list-style: none;
            display: flex;
            flex-direction: column;
            gap: 5px;
            li {
                padding: 5px;
                font-size: 13px;
                font-weight: 400;
                color: #fff;
                &:hover {
                    color: #999;
                    cursor: pointer;
                    background-color: #fff;
                    text-decoration: underline;
                    border-top-right-radius: 3px;
                    border-bottom-right-radius: 3px;
                }
            }
        }
    }
    .areaWork {
        h6 {
            font-size: 14px;
            font-weight: 500;
            color: white;
            text-transform: uppercase;
            margin-bottom: 5px;
        }
        ul {
            margin: 0;
            padding: 0;
            list-style: none;
            display: flex;
            flex-direction: column;
            gap: 5px;
            li {
                padding: 5px;
                font-size: 13px;
                font-weight: 400;
                color: #fff;
            }
        }
    }
    .copayRight{
        h6 {
            font-size: 14px;
            font-weight: 500;
            color: white;
            text-transform: uppercase;
            margin-bottom: 5px;
        }
        p{
            color: white;
            font-size: 13px;
            font-weight: 400;
            line-height: 25px;
        }
    }
}
