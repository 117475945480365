.regions{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-top: 15px;
    .RegionBox{
        display: flex;
        flex-direction: column;
        flex: 1;
        background-color: #fff;
        padding: 20px;
        box-shadow: 0 1px 2px rgba(0,0,0,0.5);
        height: 130px;
        border-radius: 10px;
        .RegionHeader{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            color: #555;
            text-transform: uppercase;
            font-size: 14px;
            font-weight: 600;
            line-height: 28px;
        }
        .districts{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 8px;
            margin-top: 10px;
            .districtItems{
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                gap: 3px;
                span{
                    color: #555;
                    font-size: 12px;
                    font-weight: 400;
                }
                .ItemIcon{
                    border-radius: 50%;
                    background-color: rebeccapurple;
                    font-size: 14px;
                }
            }
           
        }
       
    }
}