.sidebarContainer{
    flex: 2;
    min-height: 100vh;
    background-color: #FFF;
    padding-left: 10px;
    .sidebarMenu{
        list-style-type: none;
        margin: 0;
        padding: 0;
        li{
            margin-bottom: 5px;
            width: 100%;
            padding: 10px;
            border-bottom: 0.5px solid #999;
            a{
                text-decoration: none;
                font-size: 14px;
                font-family: 400;
                color: #666;
            }

            &:hover{
                background-color: blue;
                a{
                    color: #FFF;
                    font-size: 14px;
                    font-weight: 500;
                }
                transition: all 0.8s ease-in-out;
            }
        }
    }
}